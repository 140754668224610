import Logo from "./logo.svg";
import SearchIcon from "./search-icon.svg";
import LocationIllustration from "./location-illustration.svg";
import MobileIcon from "./mobile-icon.svg";
import EmailIcon from "./email-icon.svg";
import LocationIcon from "./location-icon.svg";
import FacebookIcon from "./facebook-icon.svg";
import LinkedInIcon from "./linkedin-icon.svg";
import TwitterIcon from "./twitter-icon.svg";
import InstagramIcon from "./insta-icon.svg";
import FAQPlusIcon from "./faq-plus.svg";
import AeroplaneIcon from "./aeroplane-icon.svg";
import BoatIcon from "./boat-icon.svg";
import BoxIcon from "./box-icon.svg";
import StarIcon from "./star-icon.svg";
import valueIcon from "./values-icon.svg";
import codeIcon from "./code-icon.svg";
import trailerIcon from "./trailer-icon.svg";
import qatarIcon from "./qatar-icon.svg";
import logisticsIcon from "./logistics-icon.svg";
import uldIcon from "./uld-icon.svg";
import containerIcon from "./container-icon.svg";
import supportIcon from "./support.svg";
import shippingIcon from "./shipping.svg";
import corporateIcon from "./corporate.svg";
import visionIcon from "./vision-icon.svg";
import missionIcon from "./mission-icon.svg";
import grid1Icon from "./grid1-icon.svg";
import grid2Icon from "./grid2-icon.svg";
import grid3Icon from "./grid3-icon.svg";
import grid4Icon from "./grid4-icon.svg";
import grid5Icon from "./grid5-icon.svg";
import serviceOne from "./serviceOne.svg";
import serviceTwo from "./serviceTwo.svg";
import serviceThree from "./serviceThree.svg";
import serviceFour from "./serviceFour.svg";
import serviceFive from "./serviceFive.svg";
import serviceSix from "./serviceSix.svg";
import serviceSeven from "./serviceSeven.svg";
import serviceEight from "./serviceEight.svg";
import serviceNine from "./serviceNine.svg";
import serviceTen from "./serviceTen.svg";
import serviceEleven from "./serviceEleven.svg";
import serviceTewelve from "./serviceTewelve.svg";
import serviceThirteen from "./serviceThirteen.svg";
import serviceFourteen from "./serviceFourteen.svg";
import bgContainer from "./bg-container-cargo-ship-sea.svg";
import aboutOne from "./aboutOne.svg";
import truck from "./truck.svg";
import airoplan from "./airplaneOutline.svg";
import notesBook from "./noteBook.svg";
import code from "./code.svg";
import location from "./location.svg";
import bgAbout from "./bgAbout.png";
import bgTracking from "./bgTracking.svg";
import phoneIcon from "./phone.svg";
import mailIcon from "./mail.svg";
import homeIcon from "./home.svg";
import menuIcon from "./menu.png";
import deliveredIcon from "./deliveredIcon.svg";
import locationTrack from "./locationIcon.svg";
import mapBg from "./mapBg.svg";
import pendingStatus from "./pendingStatus.svg";
import trackingTruck from "./trackingTruck.svg";
import blankIcon from "./dot.svg";
import homeBg from "./homeBg.webp";
import MobileHomeBg from "./mobileHome.jpg";

const IMAGES = {
  MobileHomeBg,
  homeBg,
  blankIcon,
  deliveredIcon,
  locationTrack,
  mapBg,
  pendingStatus,
  trackingTruck,
  menuIcon,
  phoneIcon,
  mailIcon,
  homeIcon,
  grid1Icon,
  grid2Icon,
  grid3Icon,
  grid4Icon,
  grid5Icon,
  missionIcon,
  visionIcon,
  supportIcon,
  shippingIcon,
  corporateIcon,
  codeIcon,
  containerIcon,
  trailerIcon,
  uldIcon,
  logisticsIcon,
  qatarIcon,
  valueIcon,
  FAQPlusIcon,
  FacebookIcon,
  LinkedInIcon,
  TwitterIcon,
  InstagramIcon,
  MobileIcon,
  EmailIcon,
  LocationIcon,
  SearchIcon,
  Logo,
  LocationIllustration,
  AeroplaneIcon,
  BoatIcon,
  BoxIcon,
  StarIcon,
  serviceOne,
  serviceTwo,
  serviceThree,
  serviceFour,
  serviceFive,
  serviceSix,
  serviceSeven,
  serviceEight,
  serviceNine,
  serviceTen,
  serviceEleven,
  serviceTewelve,
  serviceThirteen,
  serviceFourteen,
  bgContainer,
  aboutOne,
  truck,
  airoplan,
  notesBook,
  code,
  location,
  bgAbout,
  bgTracking,
};
export default IMAGES;
