function KnowledgeCenter({ imageSrc, subHead, description }) {
  return (
    <div className="flex flex-row w-[370px] md:w-[480px] lg:w-[400px] gap-4 lg:mr-8">
      <div className="flex justify-center h-24 text-center min-w-24 lg:h-32 lign-middle bg-lightBg lg:min-w-32 rounded-xl">
        <img src={imageSrc} width={58} height={50} alt="img"/>
      </div>
      <div className="my-auto">
        <p className="text-xl font-bold lg:mb-2 lg:mt-4 text-grayC-300">
          {subHead}
        </p>
        <p className="mb-4 text-sm font-medium text-grayC-100">{description}</p>
      </div>
    </div>
  );
}

export default KnowledgeCenter;
